// @flow

import React, {Component} from 'react';
import {Block, Col, Row} from 'jsxstyle';
import {StaticQuery, graphql} from 'gatsby';
import WindowsizeMonitor from './WindowSizeMonitor';

import Link from '@components/Link';
import Image from '@components/Image';
import Offer from '@components/OfferPopup';
import Button from '@components/Button';
import Dropdown from '@components/Form/Dropdown';
import {ORANGE, WHITE} from '@styles/colors';
import dropdownArrow from '@data/svg/DropdownArrow.svg';
import getNeighborhoodOptions from '@util/getNeighborhoodOptions';
import {stringToUrl} from '@util';
import MagnifyingGlass from './icons/MagnifyingGlass';

type Props = {
  title?: string,
  body?: string,
  fluid?: Object,
  height: string,
  padding?: string,
  margin?: string,
  alt?: string,
  imgStyle?: Object,
  imageContainerStyle?: Object,
  imageSrc?: string,
};

type State = {
  selectedValue: any,
};

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  md: 'screen and (min-width: 769px) and (max-width: 1279px)',
  lg: 'screen and (min-width: 1280px)',
  exLg: 'screen and (min-width: 1440px)',
};

export default class HomepageHero extends Component<Props, State> {
  state = {
    selectedValue: 'all',
  };

  getImageFromValue = () => {
    const {selectedValue} = this.state;
    if (selectedValue) {
      switch (selectedValue.toLowerCase()) {
        case 'all':
          return 'default';
        case 'nomad':
          return 'nomad';
        case 'hells-kitchen':
          return 'hellskitchen';
        default:
          return 'default';
      }
    }
  };

  handleChange = (e: Event) => {
    const {target} = e;
    // $FlowFixMe
    const {value} = target;
    this.setState({
      selectedValue: value,
    });
  };

  render() {
    const {
      title,
      body,
      height,
      padding,
      margin,
      fluid,
      alt,
      imgStyle,
      imageSrc,
      imageContainerStyle,
      images,
      excludedNeighborhoods, // Add this line
    } = this.props;
    const imageSources = images.map(node => ({
      img: {
        src: node.image.childImageSharp.fluid.src,
        imgName: node.name,
      },
    }));
    return (
      <StaticQuery
        query={graphql`
          query {
            wagtail {
              properties {
                name
                soldout
                address {
                  neighborhood {
                    slug
                    name
                  }
                }
                floorplans {
                  units {
                    available
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const searchOptions = getNeighborhoodOptions(data, excludedNeighborhoods);
          const selectedV = this.getImageFromValue();
          const selectedImageSources = [];
          imageSources.map(img => {
            if (img.img.imgName === selectedV) {
              selectedImageSources.push(img.img.src);
            }
          });

          const [selectedImageSource] = selectedImageSources;

          return (
            <WindowsizeMonitor>
              {({width}) => {
                return (
                  <Col
                    component="section"
                    height={height ? height : '100vh'}
                    padding={padding && padding}
                    margin={margin && margin}
                    width="100%"
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                    backgroundSize="cover !important"
                    background={`linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3)), url(${selectedImageSource})`}
                    backgroundPosition="center !important"
                    transition="background 0.4s"
                  >
                    <Image
                      imageSrc={imageSrc}
                      fluid={fluid}
                      imgStyle={imgStyle}
                      style={
                        imageContainerStyle
                          ? imageContainerStyle
                          : {
                              top: '0',
                              bottom: '0',
                              right: '0',
                              left: '0',
                              position: 'absolute',
                              zIndex: '-1',
                            }
                      }
                      alt={alt ? alt : 'HeroImage'}
                    />
                    <Col
                      color={WHITE}
                      alignItems="center"
                      justifyContent="center"
                      maxWidth="1360px"
                    >
                      <Block
                        component="h1"
                        fontSize="4.5rem"
                        textAlign="center"
                        margin="0"
                        mediaQueries={mediaQueries}
                        smFontSize="3rem"
                      >
                        {title}
                      </Block>
                      <Block
                        component="p"
                        textAlign="center"
                        margin="20px 0"
                        fontSize="1.3rem"
                        lineHeight="1.5rem"
                        fontWeight="bold"
                        width="60%"
                        mediaQueries={mediaQueries}
                        smWidth="90%"
                        smFontSize="1rem"
                        smLineHeight="1.2rem"
                      >
                        {body}
                      </Block>
                      <Row
                        margin="20px auto"
                        lgWidth="65%"
                        mediaQueries={mediaQueries}
                        smWidth="90%"
                        mdWidth="50%"
                      >
                        {/*<Dropdown
                          options={searchOptions}
                          width="80%"
                          mdWidth="150%"
                          smWidth="200%"
                          backgroundColor={WHITE}
                          padding="15px 30px"
                          arrow={dropdownArrow}
                          defaultValueText="Any Neighborhood"
                          defaultValue="all"
                          handleChange={e => this.handleChange(e)}
                          selectedValue={this.state.selectedValue}
                          fontWeight="bold"
                          height="60px"
                          fontSize="1.2rem"
                          mediaQueries={mediaQueries}
                        />
                        <Button
                          buttonLink={`/search/?neighborhood=${stringToUrl(
                            this.state.selectedValue
                              ? this.state.selectedValue
                              : 'all',
                          )}`}
                          buttonHeight="60px"
                          ariaLabel={
                            width > 767
                              ? ''
                              : `Press enter to search for the available units in ${
                                  this.state.selectedValue
                                    ? this.state.selectedValue
                                    : 'all neighborhoods'
                                }`
                          }
                          buttonColor={ORANGE}
                          buttonWidth="100%"
                          smWidth="20%"
                          fontSize="1.2rem"
                        >
                          {width > 767 ? 'search' : <MagnifyingGlass />}
                        </Button>*/}
                        <Button
                          buttonLink={`contact`}
                          buttonHeight="60px"
                          ariaLabel={'Navigate to contact page'}
                          buttonColor={ORANGE}
                          buttonWidth="100%"
                          smWidth="20%"
                          fontSize="1.2rem"
                        >
                          Have Questions? Contact Us
                        </Button>
                      </Row>
                      {/*<Link
                        href="/contact/"
                        style={{
                          color: WHITE,
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          textTransform: 'capitalize',
                          textDecoration: 'underline',
                        }}
                      >
                        have questions? contact us
                      </Link>*/}
                    </Col>
                    <Offer offerLength="The apt you want could be gone in a NY minute, and we are ready to welcome you home. Please contact us today." />
                  </Col>
                );
              }}
            </WindowsizeMonitor>
          );
        }}
      />
    );
  }
}
