import React, {Fragment} from 'react';

import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import Layout from '@layouts';
import HomepageHero from '@components/HomepageHero';
import SectionTitle from '@components/SectionTitle';
import PreFooter from '@components/PreFooter';
import Button from '@components/Button';
import ImageRow from '@components/ImageRow';
import Carousel from '@components/Carousel';
import Section from '@components/layout/Section';
import FeaturedNeighborhoodsRow from '@components/FeaturedNeighborhoodsRow';
import {stripTagsFromWagtail, propertyHasUnits} from '@util';
import {BLUE, ORANGE} from '@styles/colors';

import {SCHEMA_MAIN} from '@util/googleSchema';

import type {PropertyType} from '@types-local';

type Props = {
  data: {
    wagtail: {
      ...PropertyType[],
    },
  },
};

export default ({data}: Props) => {
  const {page, properties} = data.wagtail;
  const {
    seoTitle,
    searchDescription,
    heroHeadline,
    heroCopy,
    propertiesEyebrow,
    propertiesHeadline,
    propertiesCopy,
    propertiesCtaText,
    propertiesCtaLink,
    neighborhoodsEyebrow,
    neighborhoodsHeadline,
    neighborhoodsCopy,
    neighborhoodsCtaText,
    neighborhoodsCtaLink,
    aboutEyebrow,
    aboutHeadline,
    aboutCopy,
    aboutCtaText,
    aboutCtaLink,
    aboutImages,
  } = page;

  // Define the neighborhoods to exclude
  const excludedNeighborhoods = ['NoHo', 'Upper East Side', "Hell's Kitchen"];
  const neighborhoodCallouts = data.allHomepageJson.edges[0].node.neighborhoods
    .filter(neighborhood => !excludedNeighborhoods.includes(neighborhood.name));

  //console.log(data.allHomepageJson.edges[0].node.neighborhoods)
  // Adjusted filter for FeaturedNeighborhoodsRow to only exclude "Hell's Kitchen"
  const featuredNeighborhoodCallouts = data.allHomepageJson.edges[0].node.neighborhoods
    .filter(neighborhood => neighborhood.linkCopy !== "Hell's Kitchen");

  const propertiesWithUnits = properties
    .filter(property => 
      propertyHasUnits(property) &&
      property.name !== '298 Mulberry' && // Exclude '298 Mulberry'
      !excludedNeighborhoods.includes(property.address.neighborhood?.[0]?.name)
    );

  
  const heroImages = data.allHomepageJson.edges[1].node.heros;

  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{seoTitle}</title>
        <meta name="description" content={searchDescription} />
        <script type="application/ld+json">
          {JSON.stringify(SCHEMA_MAIN)}
        </script>
      </Helmet>
      <Layout>
        <HomepageHero
          title={heroHeadline}
          body={stripTagsFromWagtail(heroCopy)}
          images={heroImages}
          excludedNeighborhoods={excludedNeighborhoods}
        />
        {/*<Section padding="80px 0 40px 0">
          <SectionTitle
            eyebrow={propertiesEyebrow}
            headline={propertiesHeadline}
            dividerColor={ORANGE}
            copy={stripTagsFromWagtail(propertiesCopy)}
            button={
              <Button
                buttonText={propertiesCtaText}
                buttonLink={propertiesCtaLink}
                buttonColor={BLUE}
                buttonWidth="fit-content"
                margin="auto"
              />
            }
          />
          <Carousel propertiesData={propertiesWithUnits} showNextButton={propertiesWithUnits.length > 1} />
        </Section>
        <Section>
          <SectionTitle
            eyebrow={neighborhoodsEyebrow}
            headline={neighborhoodsHeadline}
            dividerColor={ORANGE}
            copy={stripTagsFromWagtail(neighborhoodsCopy)}
            button={
              <Button
                buttonText={neighborhoodsCtaText}
                buttonLink={neighborhoodsCtaLink}
                buttonColor={BLUE}
                buttonWidth="fit-content"
                margin="auto"
              />
            }
          />
          <FeaturedNeighborhoodsRow data={featuredNeighborhoodCallouts} />
        </Section>*/}
        <Section>
          <SectionTitle
            eyebrow={aboutEyebrow}
            headline={aboutHeadline}
            dividerColor={ORANGE}
            copy={stripTagsFromWagtail(aboutCopy)}
            button={
              <Button
                buttonText={aboutCtaText}
                buttonLink={aboutCtaLink}
                buttonColor={BLUE}
                buttonWidth="fit-content"
                margin="auto"
              />
            }
          />
          <ImageRow images={aboutImages} />
        </Section>
        <PreFooter />
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  fragment HomepageFragment on Wagtail_HomePage {
    seoTitle
    searchDescription
    heroCopy
    heroHeadline
    heroImage {
      url
    }
    propertiesEyebrow
    propertiesHeadline
    propertiesCopy
    propertiesCtaText
    propertiesCtaLink
    neighborhoodsEyebrow
    neighborhoodsHeadline
    neighborhoodsCopy
    neighborhoodsCtaText
    neighborhoodsCtaLink
    aboutEyebrow
    aboutHeadline
    aboutCopy
    aboutCtaText
    aboutCtaLink
    aboutImages {
      image {
        id
        url
        title
      }
    }
  }

  query {
    wagtail {
      page(slug: "home") {
        ...HomepageFragment
      }
      properties {
        id
        name
        soldout
        description
        address {
          neighborhood {
            slug
            name
          }
        }
        floorplans {
          units {
            available
          }
        }
      }
    }
    allHomepageJson {
      edges {
        node {
          heros {
            id
            name
            image {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          neighborhoods {
            link
            linkCopy
            description
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`;
