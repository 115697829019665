// @flow

import React, {Component, Fragment} from 'react';
import {Col, Block} from 'jsxstyle';

import Link from '@components/Link';
import Button from '@components/Button';
import Arrow from '@components/icons/Arrow';
import Image from '@components/Image';
import Paragraph from '@components/Paragraph';

import {ORANGE, WHITE, BLUE, TRANSPARENT} from '@styles/colors';

export type Props = {
  image: Object,
  link: string,
  linkCopy: string,
  description: string,
};

type State = {
  hovered: boolean,
};

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

export default class TallCard extends Component<Props, State> {
  state = {
    hovered: false,
  };

  handleMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  render() {
    const {image, link, linkCopy, description} = this.props;
    return (
      <Col
        height="100%"
        width="33.4%"
        position="relative"
        background={`linear-gradient(rgba(20, 20, 20, 0.9), rgba(20, 20, 20, 0.9))`}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        mediaQueries={mediaQueries}
        smWidth="100%"
        flexGrow={1}
        smHeight="400px"
        zIndex={1}
        props={{
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.handleMouseLeave,
        }}
      >
        <Image
          fluid={image.childImageSharp.fluid}
          imgStyle={{position: 'relative', zIndex: -1}}
          style={{height: '100%', width: '100%', position: 'absolute'}}
          alt={linkCopy}
        />
        <Col
          height="100%"
          backgroundColor={BLUE}
          transition="opacity 0.4s ease-in-out"
          opacity={this.state.hovered ? 1 : 0}
          position="relative"
        />
        <Col
          position="absolute"
          top={200}
          left={30}
          width="80%"
          mediaQueries={mediaQueries}
          smTop={'100px'}
        >
          <Block
            height="3px"
            width="30%"
            marginBottom={30}
            backgroundColor={this.state.hovered ? TRANSPARENT : ORANGE}
          />
          <Link
            href={link}
            style={{
              fontSize: '2rem',
              fontFamily: 'abril-fatface',
              color: WHITE,
              textDecoration: 'none',
              textTransform: 'capitalize',
              justifyContent: 'space-between',
              marginBottom: '20px',
            }}
          >
            {linkCopy}
            {!this.state.hovered && <Arrow margin="0 0 0 5%" />}
          </Link>
          {this.state.hovered && (
            <Fragment>
              <Paragraph
                color={WHITE}
                margin="0 0 60px 0"
                smMargin="0 0 20px 0"
                textAlign="left"
              >
                {description}
              </Paragraph>
              <div>
              <Button
                buttonText={`Check Out ${linkCopy}`}
                buttonLink={link}
                buttonColor={TRANSPARENT}
                buttonBorder={`1px solid ${WHITE}`}
                buttonWidth="fit-content"
                margin="0 0 20px 0"
              />
              </div>
            </Fragment>
          )}
        </Col>
      </Col>
    );
  }
}
